<template>
    <div class="home" id="quotaReviewList">
      <div style="text-align: left" >
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="购方名称">
              <el-input v-model="queryForm.buyersName" placeholder="请输入查询" :clearable="true"></el-input>
            </el-form-item>
            <el-date-picker value-format="yyyy-MM-dd"
                v-model="queryForm.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          <el-form-item>
            <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
            <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: left;margin-bottom: 20px;">
        <!-- <el-button type="primary" icon="el-icon-plus" @click="openUploadFrom()">上传发票数据</el-button> -->
        <el-button type="primary" icon="el-icon-plus" @click="infoDialogVisible=true">保存发票信息</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="openSaveDialog">申请开票</el-button>
      </div>
      <div>
          <el-table  :header-cell-style="tableHeader" :cell-style="tableCell" :data="tableData" fit border style="width: 100%">
          <el-table-column type="index" width="50" label="" />
          <el-table-column prop="arInvoiceCode" :show-overflow-tooltip="true"  label="应收发票号" />
          <el-table-column prop="arInvoiceDate" :show-overflow-tooltip="true" label="应收发票日期" />
          <el-table-column prop="arInvoiceId" :show-overflow-tooltip="true" label="应收发票 ID" />
          <el-table-column prop="arInvoiceLineId" :show-overflow-tooltip="true" label="应收发票行 ID" />
          <el-table-column prop="buyersAddressTel" :show-overflow-tooltip="true" label="购方地址电话" />
          <el-table-column prop="buyersBankAcc" :show-overflow-tooltip="true" label="购方银行帐号" />
          <el-table-column prop="buyersName" :show-overflow-tooltip="true" label="购方名称" />
          <el-table-column prop="buyersTaxCode" :show-overflow-tooltip="true" label="购方税号" />
          <!-- <el-table-column prop="itemCode" :show-overflow-tooltip="true" label="物料编码" /> -->
          <!-- <el-table-column prop="itemName" :show-overflow-tooltip="true" label="物料说明" /> -->
          <!-- <el-table-column prop="itemSpec" :show-overflow-tooltip="true" label="规格型号" /> -->
          <!-- <el-table-column prop="itemUom" :show-overflow-tooltip="true" label="计量单位" /> -->
          <el-table-column :show-overflow-tooltip="true" label="金额">
            <template slot-scope="scope">{{accDiv(scope.row.amount,100)}}</template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="不含税金额">
            <template slot-scope="scope">{{accDiv(scope.row.noTaxAmount,100)}}</template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="单价">
            <template slot-scope="scope">{{accDiv(scope.row.price,100)}}</template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="数量">
            <template slot-scope="scope">{{scope.row.quantity}}</template>
          </el-table-column>
          <el-table-column prop="sallerTaxCode" :show-overflow-tooltip="true" label="销方税号" />
          <el-table-column :show-overflow-tooltip="true" label="税额">
            <template slot-scope="scope">{{accDiv(scope.row.taxAmount,100)}}</template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="税率">
            <template slot-scope="scope">{{accDiv(scope.row.taxRate,1000000)}}</template>
          </el-table-column>
          <el-table-column prop="status" :show-overflow-tooltip="true"  label="状态">
            <template slot-scope="scope">
              <div v-for="(item, index) in itemList" v-bind:key="index" >
                <div v-if="item.datavalue == scope.row.status">
                  {{  item.name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" :show-overflow-tooltip="true" label="创建时间" />
          <!-- <el-table-column label="操作" fixed="right" >
            <template slot-scope="scope">
              <el-button type="text" size="small" v-if="scope.row.status == 0" @click="saveDialogVisible=true" style="color: #FFAC1D">申请开票</el-button>
              <el-button type="text" size="small" v-else-if="scope.row.status == 6" @click="saveDialogVisible=true" style="color: #FFAC1D">重新申请</el-button>
            </template>
          </el-table-column> -->
        </el-table>
       <el-pagination
          id="page"
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          @prev-click="currentChange"
          @next-click="currentChange"
          :page-sizes="[5, 10, 20]"
          :page-size="queryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total" >
        </el-pagination>
      </div>
      <div>
        <!-- 外层 -->
        
        <!-- <el-dialog title="上传发票数据" :visible.sync="dialogFormVisible" width="50%" style="text-align: initial;" @closed="afteraddOrUpdateDialogClose">
          
          <el-form :model="updateForm" :label-position="'left'" ref="updateForm" :rules="updateFormRules" label-width="150" class="updateForm" >
            <el-form-item label="上传发票数据"  prop="isFile" >
              <el-upload
                class="upload-demo"
                ref="upload"
                :action="upload"
                :on-preview="handlePreview"
                :on-error="handleError"
                :on-remove="handleRemove"
                :on-success="handlesuccess"
                :file-list="fileList"
                :limit=1
                accept=".xls,.xlsx"
                :data="uploadJson"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件。</div>
              </el-upload>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitUploadFrom">确 定</el-button>
          </div>
        </el-dialog> -->
  
        <el-dialog title="保存发票信息" :visible.sync="infoDialogVisible" width="400px">
            <el-form :model="infoForm" ref="infoForm" :rules="infoRules" label-position="left" label-width="110px">
              <el-form-item label="名称" prop="buyersName">
                  <el-col :span="18">
                    <el-input v-model="infoForm.buyersName"></el-input>
                  </el-col>
              </el-form-item>
              <el-form-item label="税号" prop="buyersTaxCode">
                  <el-col :span="18">
                    <el-input v-model="infoForm.buyersTaxCode"></el-input>
                  </el-col>
              </el-form-item>  
              <el-form-item label="地址、电话" prop="buyersAddressTel">
                  <el-col :span="18">
                    <el-input v-model="infoForm.buyersAddressTel"></el-input>
                  </el-col>
              </el-form-item>  
              <el-form-item label="开户行及账号" prop="buyersBankAcc">
                  <el-col :span="18">
                    <el-input v-model="infoForm.buyersBankAcc"></el-input>
                  </el-col>
              </el-form-item>         
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="infoDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit('infoForm')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="申请开票" top="8vh" :visible.sync="saveDialogVisible" width="800px">
            <el-form :model="saveForm" ref="saveForm" :rules="rules" label-position="left" label-width="110px">
              <el-form-item label="公司名称" prop="buyersName" style="margin-left: 60px;">
                <el-col :span="18">
                  <el-select v-model="selectInvoiceId" @change="change" placeholder="请选择公司名称" style="width:440px">
                    <el-option v-for="incoiceInfo in invoiceInfoList" :label="incoiceInfo.buyersName" :value="incoiceInfo.id"></el-option>
                  </el-select>
                </el-col>
              </el-form-item>
              <el-form-item label="税号" style="margin-left: 60px;">
                  <el-col :span="18">
                    <el-input v-model="saveForm.buyersTaxCode" disabled></el-input>
                  </el-col>
              </el-form-item>
              <el-form-item label="地址、电话" style="margin-left: 60px;">
                  <el-col :span="18">
                    <el-input type="textarea" v-model="saveForm.buyersAddressTel" disabled></el-input>
                  </el-col>
              </el-form-item>
              <el-form-item label="开户行及账号" style="margin-left: 60px;">
                  <el-col :span="18">
                    <el-input v-model="saveForm.buyersBankAcc" disabled></el-input>
                  </el-col>
              </el-form-item>
              <el-form-item label="单位" style="display:inline-block">
                  <el-col :span="18">
                    <el-input v-model="saveForm.itemUom" disabled></el-input>
                  </el-col>
              </el-form-item>
              <el-form-item label="数量" style="display:inline-block">
                  <el-col :span="18">
                    <el-input v-model="saveForm.quantity" disabled></el-input>
                  </el-col>
              </el-form-item>
              <el-form-item label="开票类目" prop="itemName" style="display:inline-block">
                <el-col :span="18">
                  <el-select v-model="saveForm.itemName" placeholder="请选择开票类目">
                    <el-option label="*人力资源服务*劳务派遣" value="*人力资源服务*劳务派遣"></el-option>
                    <el-option label="*人力资源服务*服务费" value="*人力资源服务*服务费"></el-option>
                    <el-option label="物流辅助服务*分拣服务" value="*物流辅助服务*分拣服务"></el-option>
                  </el-select>
                </el-col>
              </el-form-item>
              <el-form-item label="开票金额" prop="amount" style="display:inline-block">
                  <el-col :span="18">
                    <el-input v-model.number="saveForm.amount" placeholder="请输入开票金额"></el-input>
                  </el-col>
              </el-form-item>       
              <el-form-item label="税率" prop="taxRate" style="display:inline-block">
                  <el-col :span="18">
                    <el-input v-model.number="saveForm.taxRate" placeholder="请输入税率(1-100)"></el-input>
                  </el-col>
              </el-form-item>  
              <el-form-item label="开票类型" prop="invoiceType" style="display:inline-block">
                <el-col :span="18">
                  <el-select v-model="saveForm.invoiceType" placeholder="请选择开票类目">
                    <el-option label="增值税专用发票" value="S"></el-option>
                    <el-option label="普通发票" value="C"></el-option>
                  </el-select>
                </el-col>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="saveDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('saveForm')">确 定</el-button>
            </div>
        </el-dialog>

      </div>
    </div>
  </template>
  <script>
  
  import XLSX from "xlsx"
  
  import {accMul} from '../../util/calculateUtils';
  import { re } from 'mathjs'
  import { Progress } from "element-ui"
  import { title } from "process"
  
  export default {
      data() {
        return {
          //upload:process.env.VUE_APP_BASE_URL+'/invoice/importArInvoice',
          // 0为汇总
          type: 0,
          queryForm: {
            time:[],
            status: [2],
            buyersName:'',
            pageNo: 1,
            pageSize: 10
          },
          total:0,
          // 这个站点对象集合
          // fullscreenLoading: false,
          saveDialogVisible:false,
          infoDialogVisible:false,
          groupList: [],
          options: [],
          // 状态字典列表
          summaryStateList:[],
          // 以表示排列的数组
          summaryArray: null,
          // 表格对象
          tableData: [],
          //开票信息
          invoiceInfoList:[],
          //选中的编号
          selectInvoiceId:null,
          // input: '',
          saveForm: {
            itemName:'',
            quantity:'1',
            itemUom:'月',
            amount:'',
            taxRate:'',
            invoiceType:"",
            buyersName:'',
            buyersTaxCode:'',
            buyersAddressTel:'',
            buyersBankAcc:''
          },
          infoForm:{
            buyersName:"",
            buyersTaxCode:"",
            buyersAddressTel:"",
            buyersBankAcc:""
          },
          // updateFormRules: {
          //   isFile:[
          //      { required: true, message: '请选择文件！', trigger: 'change' }
          //   ]
          // },
          rules:{
            buyersName:[
            { required : true, message : "请选择公司名称!", trigger : "change"}
            ],
            itemName:[
              { required : true, message : "请选择开票类目!", trigger : "change"}
            ],
            amount:[
              { required : true, message : "请输入开票金额!" , trigger : "change"}
            ],
            taxRate:[
              { required : true, message : "请输入税率!" , trigger : "change"}
            ],
            invoiceType:[
              { required : true, message : "请选择开票类型!" , trigger : "change"}
            ],
          },
          infoRules:{
            buyersName:[
              { required : true, message : "请输入名称!", trigger : "change"}
            ],
            buyersTaxCode:[
              { required : true, message : "请输入税号!" , trigger : "change"}
            ],
            buyersAddressTel:[
              { required : true, message : "请输入地址、电话!" , trigger : "change"}
            ],
            buyersBankAcc:[
              { required : true, message : "请输入开户行及账号!" , trigger : "change"}
            ],
          },
          uploadJson:{
            json: {},
            userId: '',
            sessionId:'',
            type: process.env.VUE_APP_UPLOAD_TYPE,
            queue: process.env.VUE_APP_UPLOAD_SALARY_SUMMARY_QUENCE_NAME
          },
          formInline: {
            user: '',
            region: ''
          },
          dialogFormVisible: false,
          innerVisible: false,
          fileList: [],
          auditMsg: {
            dialogVisible: false,
            msg: '确认撤销审核该汇总表吗？',
            msgClass: 'submitMsg',
            buttonClass: 'submitButton',
            // 提交为0，撤销为1
            status: 0
          },
          queryParams: {
          pageNo: 1,
          pageSize: 10,
          code: "",
          name: "",
        },
        itemList: [],
        diction: {
                    code: "invoiceStatus",
          },
        }
      },
      computed: {

      },
      watch: {
        queryFormGetGroupIds(newVal, oldVal){
          this.queryForm.groupId = newVal;
        }
      },
      // 实例创建完成后调用
  
      mounted: function(){
                  this.findDictionariesItemList(this.diction);
          },
      async created(){
          let flag = this;
        // 获取分組 信息
        await this.api.platformApi.getGroupTree({}).then((res) => {
          res = res.data
          if(res.code == 200){
            flag.options = res.data;
          //   flag.loading = false;
          }
        })
        this.loadTable();
      },
      methods: {
         // 分页查看薪资信息列表
          async loadTable(){
            console.log(this.queryForm);
              this.loading = true;
              let flag = this;
              //getStaffList
              await this.api.invoiceApi.invoiceList(this.queryForm).then((res) => {
                  res = res.data
                  console.log("----发票列表-----",res);
                  // flag.loading = false;
                  if(res.code == 200){
                      flag.tableData = res.data.records;
                      flag.total = res.data.total
                      console.log(flag.tableData);
                  }else{
              this.tableData =  null;
              this.total = 0;
              this.$message.error(res.msg);
            }
              })
          },
        toFloat(val){
              if(this.isEntity(val)){
                  return '';
              }
              return parseFloat(val)/10000+"%";
          },
        // 点击上传
        // openUploadFrom(){
        //   this.dialogFormVisible = true;
        // },
        // 分页器 size改变是触发
         //current-change
        currentChange(page){
          this.queryForm.pageNo = page;
          this.loadTable();
        },
        submitForm(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
              this.apply()
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        submit(formName){
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.saveInvoiceInfo();
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        //保存发票信息
        async saveInvoiceInfo(){
          this.infoDialogVisible=false
          await this.api.invoiceApi.saveInvoiceInfo(this.infoForm).then(res =>{
            if(res.data.code==200){
                this.$message({
                    message: '操作成功！',
                    type: 'success'
                });
              }else{
                this.$message.error('操作失败！');
              }
              this.infoForm.buyersName=''
              this.infoForm.buyersTaxCode=''
              this.infoForm.buyersAddressTel=''
              this.infoForm.buyersBankAcc=''
          })
        },
        openSaveDialog(){
          this.saveDialogVisible = true
          this.loadInvoiceInfo()
        },
        async loadInvoiceInfo(){
          let params = {}
          await this.api.invoiceApi.invoiceInfoList(params).then(res=>{
            console.log("----------",res);
            if(res.data.code==200){
              this.invoiceInfoList = res.data.data
            }else{
              this.$message.error(res.data.msg);
            }
          })
        },
        change(row){
          let invoiceList = this.invoiceInfoList.filter(function(item){return item.id == row});
          invoiceList = invoiceList[0]
          this.saveForm.buyersName = invoiceList.buyersName
          this.saveForm.buyersTaxCode = invoiceList.buyersTaxCode
          this.saveForm.buyersAddressTel = invoiceList.buyersAddressTel
          this.saveForm.buyersBankAcc = invoiceList.buyersBankAcc
        },
        //申请开票
        async apply(){
          this.saveDialogVisible=false
          this.saveForm.amount = accMul(this.saveForm.amount,100);
          this.saveForm.taxRate = accMul(this.saveForm.taxRate,10000)
          await this.api.invoiceApi.apply(this.saveForm).then(res =>{
            console.log("----------",res);
            if(res.data.code==200){
              this.$message({
                  message: '操作成功！',
                  type: 'success'
              });
              this.loadTable();
            }else{
              this.$message.error('操作失败！');
              this.loadTable();
            }
            this.saveForm.itemName=''
            this.saveForm.amount=''
            this.saveForm.taxRate=''
            this.saveForm.invoiceType=''
            this.saveForm.buyersName='',
            this.saveForm.buyersTaxCode='',
            this.saveForm.buyersAddressTel='',
            this.saveForm.buyersBankAcc=''
          });
        },
        // size-change
        sizeChange(size){
          this.queryForm.pageSize = size;
          this.loadTable();
        },
        // 上传表单
        // submitUploadFrom(){
        //   console.log('开始上传');
        //   this.uploadJson.sessionId = this.$getCookie("sessionId");
        //   this.$refs.upload.submit();
        //   console.log('上传成功！');
        // },
        // afteraddOrUpdateDialogClose 清空弹窗表单
        // afteraddOrUpdateDialogClose(){
        //     this.resetForm('updateForm');
        //     this.$refs.upload.$data.uploadFiles = [];
        // },
        // resetForm(formName) {
        //   this.$refs[formName].resetFields();
        // },
        // 重置
        onReset(){
          this.queryForm.time=[];
          this.queryForm.status= [2]
          this.queryForm.buyersName=''
        },
        // 上传表格
        // submitUpload() {
        //   console.log('submitUpload!');
        //   this.$refs.upload.submit();
        // },
        // 删除一选择的上传文件函数
        // handleRemove(file, fileList) {
        //   console.log('handleRemove!');
        //   console.log(file, fileList);
        // },
        // 点击文件列表中已上传的文件时的钩子
        // handlePreview(file) {
        //   console.log('handlePreview!');
        //   console.log(file);
        // },
        // 上传失败的钩子
        // handleError(err, file, fileList){
        //   this.$message.error('上传失败！');
        // },
        // 上传成功的钩子
        // handlesuccess(response, file, fileList){
        //   this.fileList = []
        //   this.dialogFormVisible = false;
        //   this.fullscreenLoading = true;
        //   setTimeout(() => {
        //     this.fullscreenLoading = false;
        //     this.$message({
        //     message: '上传成功！',
        //     type: 'success'
        //   });
        //     this.loadTable();
        //   }, 2000);
        //   console.log('handlesuccess');
        // },
        async findDictionariesItemList(param) {
                  let response = await this.api.platformApi.findDictionariesItemList(param);
                  console.log("数据字典", response);
                  this.itemList = response.data.data;
              },
        tableHeader(column) {
          return "height:36px;background-color:#fafafa;color:#333333;padding:0";
        },
        tableCell(column) {
          return "height:40px;background-color:#fafafa;color:#000000;padding:0";
        },
        //除法函数
        accDiv(arg1, arg2){
         if(arg1!=null){
          var t1 = 0, t2 = 0, r1, r2;
          try {
            t1 = arg1.toString().split(".")[1].length;
          }
          catch (e) {
          }
          try {
            t2 = arg2.toString().split(".")[1].length;
          }
          catch (e) {
          }
          r1 = Number(arg1.toString().replace(".", ""));
          r2 = Number(arg2.toString().replace(".", ""));
          return (r1 / r2) * Math.pow(10, t2 - t1);
         }
         else{
          return null
         }
        }
      },
  }
  </script>
  
  <style scoped>
    .submitMsg{
      color: #09BF88;
      font-size: 1.5rem;
      
    }
    .submitButton{
      background-color: #09BF88;
      border: none;
    }
    .submitButton:hover{
      background-color: #3ACCA0;
    }
    .repealMsg{
      color: #FFAC1D;
      font-size: 1.5rem;
    }
    .repealButton{
      background-color: #FFAC1D;
      border: none;
    }
    .repealButton:hover{
      background-color: #FFBD4A;
    }
  
    .cancellationMsg{
      color: #F78969;
      font-size: 1.5rem;
    }
    .cancellationButton{
      background-color: #F78969;
      border: none;
    }
    .cancellationButton:hover{
      background-color: #F789;
    }
  /* -------------------------------------- */
  
  #page {
    float: right;
    margin-top: 15px;
  }
  
  #quotaReviewList {
    overflow: hidden;
    background-color: white;
    padding: 15px 20px 100px;
  }
  
  .el-dialog__body {
    border-top: 1px solid rgba(228, 228, 228, 1);
    border-bottom: 1px solid rgb(228, 228, 228, 1);
    padding-left: 0;
    padding-right: 0;
  }
  
  .el-drawer.rtl {
    overflow: scroll;
  }
  
  .updateForm{
    width: 90%;
    margin: 0 auto;
  }
  .parseXlsxBottom{
    text-align: end;
    padding: 20px;
  }
  </style>
  
  
  